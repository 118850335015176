<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item :to="{ name: 'BookingPresetList' }" active>
              個人預約班表
            </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>

    <b-card>
      <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
        <h4 class="col-12 col-xl-8 mb-2 font-weight-bold">個人預約班表</h4>
      </div>
      <div class="d-flex mb-4 mb-xl-2 justify-content-between">
        <div class="d-flex align-items-center">
          <b-form-select v-model="query.preset_id" :options="bookingPresetsOptions" @change="handlePageResetWithoutReload"/>
        </div>

        <div class="d-flex flex-column flex-md-row align-items-center">
          <b-button
            v-if="checkPermission([consts.MY_BOOKING_SHIFT])"
            class="flex-shrink-0 mb-2 mb-md-0 mr-md-2"
            variant="primary"
            :to="{ name: 'BookingShiftCreate', query: { preset_id: query.preset_id } }"
            :disabled="!isStaffShiftSelectionEnabled"
          >
            <i class="fa fa-plus"></i>新增
          </b-button>
          <b-form-select v-model="query.shift_type" :options="shiftTypeOptions" class="mr-2" @change="handlePageResetWithoutReload"/>
          <div class="d-flex align-items-stretch">
            <DateSearchPicker
              class="mr-1"
              v-model="query"
              startedPlaceholder='開始時間' endedPlaceholder="結束時間"
              @input="handleDateChange"
            />
            <b-button style="height: auto;" @click="handlePageResetWithoutReload">
              <i class="fa fa-search"></i>
            </b-button>
          </div>
        </div>
      </div>
      <b-card-text>
        <div class="row">
          <div class="col-12">
            <b-overlay :show="showLoading" rounded="sm">
              <b-table
                striped
                hover
                responsive
                :items="bookingShifts"
                :fields="fields"
              >
                <template #cell(actions)="data">
                  <b-button
                    class="mr-1"
                    v-if="checkPermission([consts.MY_BOOKING_SHIFT])"
                    variant="inverse-primary"
                    :to="{
                      name: 'BookingShiftView',
                      params: { id: data.item.id },
                    }"
                  >
                    <span class="mdi mdi-eye"></span> 查看
                  </b-button>
                  <b-button
                    class="mr-1"
                    v-if="checkPermission([consts.MY_BOOKING_SHIFT])"
                    variant="inverse-warning"
                    :to="{
                      name: 'BookingShiftEdit',
                      params: { id: data.item.id },
                    }"
                  >
                    <span class="mdi mdi-lead-pencil"></span> 編輯
                  </b-button>
                  <b-button
                    class="mr-1"
                    v-if="checkPermission([consts.MY_BOOKING_SHIFT])"
                    variant="inverse-danger"
                    @click="deleteBookingShift(data.item)"
                  >
                    <span class="mdi mdi-delete"></span> 刪除
                  </b-button>
                </template>
              </b-table>
            </b-overlay>
          </div>
        </div>
        <CustomPagination
          :currentPage="query.page"
          :totalRows="totalRows"
          :perPage="query.per_page"
          @page-change="handlePageChange"
          @per-page-change="handlePerPageChange"
        />
      </b-card-text>
    </b-card>

    <FormUrlModal ref="formUrlModal" />
  </div>
</template>

<script>
import * as consts from "@/consts";
import PermissionChecker from "@/utils/PermissionChecker";
import { format } from "date-fns";
import bookingApi from "@/apis/booking";
import { mapGetters, mapState } from "vuex";
import CustomPagination from "@/components/Page/Dashboard/CustomPagination.vue";
import FormUrlModal from "@/components/Dashboard/Booking/FormUrlModal.vue";
import { paginationMixin } from "@/mixins/pagination";
import { updateQueryFromRoute, updateUrlWithoutReload } from "@/utils/updateUrl";
import DateSearchPicker from "@/components/Page/Dashboard/DateSearchPicker.vue";
import moment from "moment/moment";

export default {
  components: {DateSearchPicker, CustomPagination, FormUrlModal },
  mixins: [paginationMixin],
  data() {
    return {
      consts: consts,
      bookingPresets: [],
      bookingShifts: [],
      shiftTypeOptions: [
        { text: '請選擇', value: null, },
        { text: '休假', value: 'leave' },
        { text: '上班', value: 'duty' },
      ],
      showLoading: false,
      fields: [
        {
          key: "shift_type",
          label: "類型",
          sortable: false,
          formatter: (value) => {
            return value === "leave" ? "休假" : "上班";
          },
        },
        {
          key: "start_at",
          label: "開始時間",
          sortable: true,
          formatter: (value) => {
            return format(new Date(value), "yyyy-MM-dd HH:mm");
          },
        },
        {
          key: "end_at",
          label: "結束時間",
          sortable: true,
          formatter: (value) => {
            return format(new Date(value), "yyyy-MM-dd HH:mm");
          },
        },
        { key: "actions", label: "管理" },
      ],
      totalRows: 0,
      initialized: false,
      query : {
        preset_id: null,
        shift_type: null,
        start_at: null,
        end_at: null,
        per_page: 20,
        page: 1,
      },
    };
  },
  computed: {
    ...mapGetters("route", ["routeQuery"]),
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
    queryWatcher() {
      return `${this.query.page}-${this.query.per_page}-${this.query.preset_id}`;
    },
    bookingPresetsOptions() {
      return [{ text: '請選擇', value: null, }, ...this.bookingPresets.map(booking => ({
        text: booking.name,
        value: booking.id,
      }))]
    },
    isStaffShiftSelectionEnabled() {
      if (!this.query.preset_id) return false;

      const selectedPreset = this.bookingPresets.find(preset => preset.id === this.query.preset_id);

      if (!selectedPreset || !selectedPreset.config) return false;

      return selectedPreset.config.enabled_selected_staff_shift === true;
    }
  },
  watch: {
    queryWatcher: {
      handler() {
        if (!this.initialized) return;
        updateUrlWithoutReload(this.query, this.$store, this.$route);
      },
    },
    routeQuery() {
      if (!this.initialized) return;
      this.fetchBookingShifts();
    },
  },
  created() {
    this.query = updateQueryFromRoute(this.$route, this.$store, this.query);
  },
  async mounted() {
    await this.fetchBookingPresets()
    if (this.query.preset_id) {
      await this.fetchBookingShifts();
    }
    this.initialized = true;
  },
  methods: {
    checkPermission(permissions) {
      const checker = new PermissionChecker();
      return checker.check(permissions);
    },
    async fetchBookingPresets() {
      try {
        this.showLoading = true
        const response = await bookingApi.getBookingPresets({
          page: 1,
          per_page: 20,
          status: "published"
        });
        this.bookingPresets = response.data.data
        this.query.preset_id = this.query.preset_id ?? this.bookingPresets[0]?.id
      } catch (error) {
        console.error(error)
        this.$swal("錯誤", "讀取資料集項目錯誤", "error")
      } finally {
        this.showLoading = false
      }
    },
    async fetchBookingShifts() {
      if (!this.query.preset_id) {
        return;
      }

      try {
        this.showLoading = true;
        let params = { ...this.query };
        let response = await bookingApi.getBookingShifts(this.query.preset_id, params);
        this.bookingShifts = response.data.data;
        this.totalRows = response.data.meta.total;
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "讀取列表失敗", "error");
      } finally {
        this.showLoading = false;
      }
    },
    async deleteBookingShift(bookingShift) {
      try {
        this.showLoading = true;
        const result = await this.$swal({
          type: "warning",
          title: "您確定要刪除嗎？",
          text: `是否要將此班表刪除？`,
          confirmButtonText: "確定刪除",
          cancelButtonText: "不刪除",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          reverseButtons: true,
        });

        if (!result.value) {
          return;
        }

        await bookingApi.deleteBookingShift(bookingShift.id);
        this.$swal("刪除成功", "", "success");
        await this.fetchBookingShifts();
      } catch (error) {
        console.error(error);
        this.$swal("刪除失敗", "", "error");
      } finally {
        this.showLoading = false;
      }
    },
    handleDateChange(date) {
      const startAt = (date.start_at) ? moment(date.start_at).format("YYYY-MM-DD 00:00:00") : null;
      const endAt = (date.end_at) ? moment(date.end_at).format("YYYY-MM-DD 23:59:59") : null;

      this.query.start_at = startAt;
      this.query.end_at = endAt;
    },
  },
};
</script>

<style scoped>
.search-btn {
  height: 100%;
  display: flex;
  align-items: center;
}
</style>
